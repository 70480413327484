export function isProdEnv() {
  return import.meta.env.VITE_PRODUCTION_MODE === "production"
}

export function isDevEnv() {
  return import.meta.env.VITE_PRODUCTION_MODE === "develop"
}

export function isLocalEnv() {
  return import.meta.env.VITE_PRODUCTION_MODE === "local"
}
